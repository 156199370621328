import Image from "next/image";
import Link from "next/link";

const FourOFour = () => {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <Image width={500} height={150} src="/cute_cat.webp" />
      <div class="flex flex-col items-center justify-center">
        <p class="text-3xl md:text-4xl lg:text-5xl text-gray-800 mt-12">Page Not Found</p>
        <p class="md:text-lg lg:text-xl text-gray-600 mt-8 text-center">
          It seems you got a little lost, but that's okay, I can take you back
          <br/>
          In the mean time, enjoy this cute cat :)
        </p>
        <Link
          href="/"
          class="flex items-center btn px-4 py-2 mt-12 "
          title="Return Home">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <span>Back to safety</span>
        </Link>
      </div>
    </div>
  );
};

export default FourOFour;
